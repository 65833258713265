.Footer {
  /* position: sticky; */
  /* bottom: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4%;
  text-align: center;
  /* padding: 0px;
  margin: 0px; */
  width: 100%;
  opacity: 1;
  background-color: #acacb5;
  background-image: linear-gradient(
    90deg,
    #4c4c52 0%,
    #2d2b2b 50%,
    #4c4c52 100%
  );
  padding: 0;
  margin: o;
  z-index: 50;
}

.Footer a {
  text-decoration: none;
  color: #b8b8b9;
  font: bold;
  padding: 15px 0 15px;
  opacity: 1;
}

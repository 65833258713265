.Tsnb {
  position: fixed;
  display: flex;
  justify-content: space-around;
  padding-top: 150px;
  margin: 0;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-image: url('../../images/back_draw.jpg');
  background-repeat: repeat;
  background-size: 100% 100%;
  z-index: 20;
}

.Tsnb h1 {
  color: #fff;
  margin-left: 10px;
}

.Menu {
  z-index: 10;
  display: flex;
  border: 2px solid rgb(15, 49, 25);
  border-radius: 5px;
  flex-grow: 1;
  height: 85%;
  width: 30%;
  justify-content: center;
  margin: 25px 25px 25px 50px;
  background-color: #fff;
  /* background-image: linear-gradient(90deg, #f6f7e3 0%, #f3ffc9 100%); */
}

.Smeta {
  display: flex;
  height: 85%;
  width: 60%;
  justify-content: center;
  margin: 25px 50px 25px 25px;
  border: 2px solid rgb(15, 49, 25);
  border-radius: 5px;
  background-color: #eee;
}

.sale {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 150px 0;
  margin: 0;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-image: url('../../images/spb-back1.jpg');
  background-repeat: repeat;
  background-size: 100% 100%;
  z-index: 20;
}

.inSale {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 70%;
  min-height: 650px;
  min-width: 600px;
  max-width: 1000px;
  margin: auto;
  border: 2px solid black;
  border-radius: 10px;
  opacity: 0.9;
  background-color: rgb(192, 207, 216);
  box-shadow: 4px 4px 4px black;
  padding: 50px 50px;
  overflow: hidden;
}

.prices {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  max-width: 900px;
}

.MenuToggle {
  position: fixed;
  top: 60px;
  left: 60px;
  font-size: 25px;
  cursor: pointer;
  color: #fff;
  transition: opacity, left 0.22s ease-in;
  z-index: 100;
  height: 20px;
  width: 20px;
}

.MenuToggle:hover {
  opacity: 0.7;
}

.MenuToggle.open {
  left: 440px;
}

.Index {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding-top: 150px;
  flex-grow: 1;
  width: 100%;
  background-image: url('../../images/spb-back1.jpg');
  background-repeat: repeat;
  background-size: 100% 100%;
}

.Intro {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(73, 73, 73);
  width: 66%;
  margin-top: 20px;
  padding: 30px;
  font-size: 1em;
  font-weight: 400;
  text-align: justify;
  box-sizing: border-box;
  overflow: auto;
  background-color: #bacad4;
  border-radius: 1em;
  box-shadow: 4px 4px 4px black;
  opacity: 0.9;
}

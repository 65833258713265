.Drawer {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 80%;
  max-width: 420px;
  padding: 20px 10px;
  box-sizing: border-box;
  background: #fff;
  transform: translateX(0);
  transition: transform 0.3s ease 0.1s;
  z-index: 90;
}

.Drawer.close {
  transform: translateX(-420px);
}

.Drawer ul {
  list-style: none;
  margin: 0px;
  margin-left: 1em;
  padding: 100px 0 0 0px;
}

.Drawer ul li {
  display: block;
  padding: 15px;
  transition: opacity 0.5s;
}

.Drawer ul li:hover {
  /* background-color: #817e7e;
  background-image: linear-gradient(
    180deg,
    #b6b6b6 0%,
    #e6e6e6 22%,
    #ffffff 50%,
    #e6e6e6 78%,
    #b6b6b6 100%
  ); */
  border: 1px solid grey;
  border-radius: 5px;
  text-shadow: 1px 1px 0 #ffffff, 2px 2px 0 #ebebeb, 3px 3px 0 #c2c2c2;
  transform: translate(-2px, -2px);
}

.Drawer ul li a {
  color: #363d54;
  font-size: 18px;
  text-decoration: none;
  position: relative;
  padding: 0 20px 10px 20px;
  transition: opacity 0.3s;
  padding: 0;
  margin: 0;
}

.Drawer ul li a:hover,
.active {
  opacity: 0.7;
  color: #0019a7;
}

.Drawer > img {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 420px;
  height: 80px;
  margin-bottom: 20px;
}

.Catalog {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #031320;
  box-sizing: border-box;
  box-shadow: 5px 5px 5px black;
  overflow: auto;
  min-width: 300px;
  width: 100%;
}

.Catalog h1 {
  color: #031320;
}

.Head {
  text-align: center;
  margin: 0;
  padding: 0;
  height: 55px;
  font-size: 20px;
  border-radius: 2px;
  background-image: linear-gradient(
    45deg,
    #aaaaaa 0%,
    #d4d4d4 50%,
    #aaaaaa 100%
  );
  /* background-color: #488f19;
  background-image: linear-gradient(90deg, #315f12 0%, #97e871 100%); */
}

.Head h2 {
  padding: 5px;
  margin: 0px;
}

.Body {
  box-sizing: border-box;
  overflow: auto;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Smeta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #031320;
  width: 100%;
  height: 100%;
  margin: 0px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 5px 5px 5px black;
  align-items: center;
  overflow: auto;
}

.Smeta h1 {
  color: #031320;
}

.Search {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
  width: 100%;
  height: 55px;
  margin: 0px;
  padding: 15px 25px;
  border-radius: 0px;
  background-image: linear-gradient(
    45deg,
    #aaaaaa 0%,
    #d4d4d4 50%,
    #aaaaaa 100%
  );
}

.output {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* align-items: center; */
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}

.output:first-child {
  align-self: flex-start;
}

.outLoad {
  display: flex;
  flex-direction: column;
  /* flex-grow: 0.1; */
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: auto;
  height: 75%;
}

.ouytLoad .Smeta.Table {
  justify-content: center;
  align-items: center;
  border: 2px double rgb(15, 49, 25);
  border-radius: 2px;
}

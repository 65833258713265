.Logout {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0px;
  align-items: center;
}

.Head {
  border: 2px solid firebrick;
}

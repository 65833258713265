.Contacts {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 150px;
  flex-grow: 1;
  width: 100%;
  background-image: url('../../images/spb-back1.jpg');
  background-repeat: repeat;
  background-size: 100% 100%;
  /* background-image: linear-gradient(270deg, #58d1ff 0%, #3cb9ff 100%); */
}

.Contacts .contactwrapper {
  padding: 2em;
  border-radius: 0.5em;
  /* border: 1px double blue; */
  width: 75%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.Contact {
  flex: 1 1 30%;
  align-items: center;
  justify-content: center;
}

.map {
  margin: 2em;
  flex: 2 1 70%;
  align-items: stretch;
  /* justify-content: center; */
}

.map iframe {
  flex-grow: 1;
  width: 100%;
  height: 55vh;
  border: 1px double gray;
  border-radius: 0.5em;
  box-shadow: 5px 5px 5px black;
}

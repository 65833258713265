.TsnbLine {
  position: fixed;
  display: flex;
  flex-grow: 1;
  top: 0;
  width: 100%;
  margin-top: 40px;
  height: 75px;
  justify-content: center;
  text-align: center;
  z-index: 50;
  /* background-color: #5c5c5c; */
  background-color: #576983;
  box-shadow: 4px 4px 4px black;
  background-image: linear-gradient(
    90deg,
    #5c5c5c 0%,
    #1a1a1a 19%,
    #030303 50%,
    #1a1a1a 81%,
    #5c5c5c 100%
  );
  opacity: 0.7;
}

.TsnbLine h4 {
  width: 100%;
  opacity: 0.95;
  color: #f8f4e6;
  z-index: 30;
  /* text-shadow: 4px 4px 4px rgb(19, 18, 18), 0 0 0.5em red; */
  text-shadow: 4px 4px 2px rgb(19, 18, 18), 4px 4px 0.5em rgb(255, 255, 255);
  margin: 0;
  font-size: 48px;
  font-weight: 600;
}

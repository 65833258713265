.Manual {
  display: flex;
  height: 96vh;
  /* flex-grow: 1; */
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding-top: 150px;
  box-sizing: border-box;
  width: 100%;
  /* border-radius: 5px;
  border: 2px solid black; */
  /* background-color: #1e51b5;
  background-image: linear-gradient(90deg, #1e51b5 0%, #666ed8 100%); */
  background-image: url('../../images/spb-back1.jpg');
  background-repeat: repeat;
  background-size: 100% 100%;
}

.Manual > h1 {
  font-size: 2.4em;
  height: 50px;
  font-style: bold;
  width: 66%;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  padding: 20;
}

.Body {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  height: 70%;
  width: 66%;
  /* flex-grow: 1; */
  box-sizing: border-box;
  overflow: auto;
  background-color: #bacad4;
  border-radius: 0.5em;
  box-shadow: 4px 4px 4px black;
  opacity: 0.7;
}

.Link {
  all: inherit;
}

.Back {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

.Description {
  width: 66%;
}

/* [data-theme='compact'] */
.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  /* background: #f7f7f7; */
  border-radius: 2px;
  margin-bottom: 12px;
  border: 0px;
  overflow: hidden;
}

.Auth {
  display: flex;
  justify-content: center;
  padding-top: 150px;
  flex-grow: 1;
  width: 100%;
  background-color: #014064;
  background-image: linear-gradient(90deg, #014064 0%, #1878b5 100%);
  /* background-color: #2c7eb5;
  background-image: linear-gradient(90deg, #2c7eb5 0%, #6cafda 100%); */
  /* background: linear-gradient(270deg, #cef2fa 0%, #59bfef 100%); */
}

.Auth > div {
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
}

.Auth h1 {
  color: #fff;
  text-align: center;
}

.AuthForm {
  background: #eee;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  padding: 15px;
  border-radius: 5px;
}

/* @font-face {
  font-family: 'CormorantGaramond';
  src: url('./font/CormorantGaramond/CormorantGaramond-Light.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-Medium.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-Bold.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-BoldItalic.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-Italic.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-LightItalic.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-MediumItalic.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-Regular.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-SemiBold.ttf'),
    url('./font/CormorantGaramond/CormorantGaramond-SemiBoldItalic.ttf');
} */
@import '~antd/dist/antd.css';

:root {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  /* font-family: 'CormorantGaramond', Fallback, sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.legal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 150px 0;
  margin: 0;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-image: url('../../images/spb-back1.jpg');
  background-repeat: repeat;
  background-size: 100% 100%;
  z-index: 20;
}

.inLegal {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  min-height: 650px;
  min-width: 600px;
  max-width: 1000px;
  margin: auto;
  border: 2px solid black;
  border-radius: 10px;
  opacity: 0.9;
  background-color: rgb(208, 212, 216);
  box-shadow: 4px 4px 4px rgb(13, 2, 63);
  padding: 10px 30px;
  overflow: auto;
  /* justify-content: center; */
  /* text-align: center; */
}
.inLegal > h1,
h2,
h3 {
  text-align: center;
}

.legalBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: rgb(187, 229, 255);
  box-shadow: 2px 2px 2px black;
  padding: 10px 10px;
}

.legalBlock > h4 {
  color: rgb(15, 35, 66);
}

.yellowBlock {
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: rgb(241, 241, 212);
  box-shadow: 2px 2px 2px black;
  padding: 10px 10px;
}

.yellowBlock > h4 {
  color: rgb(15, 35, 66);
}

.detail {
  border-radius: 3px;
  padding: 5px 5px;
}

.certificate {
  display: flex;
  justify-content: center;
  /* margin: auto; */
  width: auto;
  padding: 5px 5px;
  border-radius: 3px;
  background-color: rgb(161, 208, 238);
  box-shadow: 2px 2px 2px black;
}

.yellowBlock > .detail > .certificate {
  /* display: flex;
  justify-content: center;
  margin: auto;
  width: auto;
  padding: 5px 5px;
  border-radius: 3px;
  box-shadow: 2px 2px 2px black; */
  background-color: rgb(233, 233, 177);
}

.Api {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 150px;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  background-image: url('../../images/spb-back1.jpg');
  background-repeat: repeat;
  background-size: 100% 100%;
  /* background-image: linear-gradient(270deg, #58d1ff 0%, #3cb9ff 100%); */
}

.Api .apiwrapper {
  padding: 2em;
  border-radius: 0.3em;
  /* border: 1px double blue; */
  width: 75%;
  height: 90%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  /* align-items: center; */
  overflow: auto;
}

.site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  /* background: #f7f7f7; */
  border-radius: 2px;
  margin-bottom: 12px;
  border: 0px;
  overflow: hidden;
  width: 100%;
}
